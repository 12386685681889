.MainSlideshow,
.CertificatesCarousel {
  .owl-dots {
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    position: absolute;
    -webkit-tap-highlight-color: transparent;
  }
  .owl-dot {
    cursor: pointer;
    display: inline-block;
    width: 15px;
    & + .owl-dot {
      margin-left: 15px;
    }
    span {
      border: 3px solid;
      border-radius: 50%;
      box-sizing: content-box;
      display: block;
      height: 0;
      overflow: hidden;
      padding-bottom: 100%;
      transition: background .3s ease;
      width: 100%;
    }
  }
}

.MainSlideshow {
  .owl-dots {
    bottom: 40px;
    z-index: 1;
  }
  .owl-dot {
    span {
      background-color: #fff;
      border-color: #fff;
    }
    &:hover, &.active {
      span {
        background: $brand-primary;
      }
    }
  }
  .owl-item {
    height: 410px;
    @include media-sm {
      height: 600px;
    }
  }
}

.MainSlideshow__Item {
  background-size: cover;
  background-position: 50% 50%;
  display: block;
  height: 100%;
}

.MainSlideshow__SlideDetails {
  position: absolute;
  left: 50%;
  top: 45%;
  transform: translate(-50%, -45%);
  width: 285px;
  @include media-sm {
    width: 650px;
  }
  @include media-md {
    left: 50px;
    transform: translate(0, -45%);
  }
  @include media-lg {
    left: 140px;
  }
}

.CertificatesCarousel {

  //.owl-dots {
  //  margin-top: $padding-base-vertical * 2;
  //  top: 100%;
  //}
  //
  //.owl-dot {
  //  span {
  //    border-color: $gray-light;
  //  }
  //  &:hover, &.active {
  //    span {
  //      background: $gray-light;
  //    }
  //  }
  //}

  margin: 22px -25px 0;

  img {
    margin: 0 25px 25px;
  }

  @include media-below-xs {
    margin: 22px 0 $padding-base-vertical * 16;
  }
}
