.StarButton {
  border-bottom: 1px solid #fff;
  display: inline-block;
  font-size: 21px;
  padding: 45px 10px 20px;
  position: relative;
}

.StarButton__Icons {
  top: 0;
  display: block;
  left: 0;
  right: 0;
  position: absolute;

  &::before, &::after {
    background-color: #fff;
    content: '';
    display: block;
    height: 1px;
    position: absolute;
    top: 50%;
    margin-top: -5px;
    width: 38%;
  }

  &::before {
    left: 0;
  }
  &::after {
    right: 0;
  }
}

.VideoButton {
  border-bottom: 1px solid #fff;
  display: inline-block;
  padding-top: 10px;
  position: relative;

  @include media-sm {
    font-size: 70px;
  }

  a {
    color: #fff;
    vertical-align: middle;
  }
  &::before {
    content: '';
    border-bottom: 1px solid #fff;
    left: 0;
    right: 0;
    position: absolute;
    top: 10px;
  }
}
  .VideoButton__Play {
    display: inline-block!important;
    width: auto!important;
    margin-left: 10px;
  }
