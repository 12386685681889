.Footer {
  background-color: #0f0f0f;
}

.Copy {
  background-color: #1a1a1a;
  position: relative;
}

.Top {
  background: url(/assets/img/topo.png) no-repeat 0 0;
  height: 26px;
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  width: 66px;
  &:hover {
    background-position: 0 100%;
  }
}

@include media-sm {
  .Copy__By {
    text-align: right;
  }
}
