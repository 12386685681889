.SocialNetworks {
  a {
    color: $gray-base;
    &:hover {
      color: $gray-light;
    }
  }
  li {
    margin-bottom: 10px;
  }
}
