.Info {
  padding: $padding-base-vertical * 16 0;
}

@include media-below-xs {
  .Info {
    padding: 40px 0;
    & > div + div {
      border-top: 1px solid #ececec;
      margin-top: 40px;
      padding-top: 40px;
    }
  }
}
