$icomoon-font-path: "fonts" !default;

$icon--bussola: "\e910";
$icon--enviar: "\e90c";
$icon--youtube: "\e90d";
$icon--especificacoes: "\e90e";
$icon--calendario: "\e90f";
$icon--certo: "\e90b";
$icon--info: "\e900";
$icon--produtos: "\e901";
$icon--empresa: "\e902";
$icon--estrelas: "\e903";
$icon--twitter: "\e904";
$icon--google-mais: "\e905";
$icon--flickr: "\e906";
$icon--vimeo: "\e907";
$icon--facebook: "\e908";
$icon--download: "\e909";
$icon--seta-menu: "\e90a";

