.CommercialCalendar {
  columns: 3;
  max-width: 300px;
  li {
    @include clearfix;
    padding: 0 30px 5px 0;
  }

  .available {
    float: right;
  }
}
