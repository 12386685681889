.navbar-brand {
  padding-top: 20px;
}

.navbar-toggle{
  //padding: 0;
  .icon-bar {
    transition: background-color .3s ease;
    width: 30px;
    & + .icon-bar {
      margin-top: 7px;
    }
  }
  &:hover .icon-bar, &[aria-expanded="true"] .icon-bar {
    background-color: $brand-primary;
  }
}
