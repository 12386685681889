body {
  font-size: 14px;
  @include media-xs {
    font-size: $font-size-base;
  }
}


@include media-xs {
  h1, .h1 {
    font-size: 105px;
  }
  h2, .h2 {
    font-size: 44px;
  }
  h3, .h3 {
    font-size: 35px;
  }
  h4, .h4 {
    font-size: 23px;
  }
  h5, .h5 {
    font-size: 20px;
  }
  h6, .h6 {
    font-size: 13px;
  }
}


.ff-serif {
  font-family: $font-family-serif;
}

.fz-h3 {
  font-size: $font-size-h3;
}

.fz-large {
  font-size: $font-size-large;
}

.fz-xl {
  font-size: $font-size-h1;
  //@include media-sm {
  //  font-size: (105/1.7) + px;
  //}
  //@include media-md {
  //  font-size: 90px;
  //}
  @include media-sm {
    font-size: 90px;
  }
}
