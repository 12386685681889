@mixin HorizontalDefinitionList {
  dd + dt::before {
    content: '\D\A';
    white-space: pre;
  }

  dt, dd {
    display: inline;
    margin: 0;
  }
}

/*
 * Adds text to definition title's end
 */
@mixin DefinitionTitleEndingText($text: ': ') {
  dt::after {
    content: $text;
  }
}

/*
 * Forces last element to be inline so there isn't a margin
 */
@mixin DefinitionDescriptionLastChildInlined {
  dd :last-child {
    display: inline;
  }
}

.HorizontalDefinitionListFull {
  @include HorizontalDefinitionList;
  @include DefinitionDescriptionLastChildInlined;
  @include DefinitionTitleEndingText;
}

.HorizontalDefinitionList {
  @include HorizontalDefinitionList;
}

.HorizontalDefinitionList--LastInline {
  @include DefinitionDescriptionLastChildInlined;
}

.HorizontalDefinitionList--Colon {
  @include DefinitionTitleEndingText;
}
