.Lang__DropdownMenu {
  border: none;
  min-width: 0;
  width: 100%;
}

@include media-sm {
  .Lang {
    margin-left: 20px;
    &::before {
      border-left: 1px solid $gray-base;
      color: $gray-light;
      content: '';
      height: 20px;
      left: -10px;
      top: $navbar-padding-vertical + 1;
      position: absolute;
    }
  }
}
