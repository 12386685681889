.Departments {
  margin-top: 2em;
  @include media-md {
    margin-top: 0;
  }
}

.Departments__Department {
  margin-bottom: 2em;
  padding: 0 10px!important;
  vertical-align: top;
  width: 100%;
  @include media-xs {
    width: 50%;
  }
}

.Departments__Department__Title {
  position: relative;
  &::before {
    content: '\2023';
    font-size: 30px;
    position: absolute;
    top: -10px;
    left: -15px;
  }
}
