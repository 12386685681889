.c-light {
  color: $gray-light;
}

.c-lighter {
  color: $gray-lighter;
}

.c-placeholder {
  color: $input-color-placeholder;
}

.c-brand {
  color: $brand-primary;
}

.bg-cover {
  background-size: cover;
}

.bgc-brand {
  background-color: $brand-primary;
}


.lh1 {
  line-height: 1;
}

.mt0 {
  margin-top: 0;
}

.mb\- {
  margin-bottom: $padding-base-vertical * .5;
}

.mb\+ {
  margin-bottom: $padding-base-vertical * 4;
}

.pb {
  padding-bottom: $padding-base-vertical;
}

.pb\+ {
  padding-bottom: $padding-base-vertical * 4;
}

.pb\+\+ {
  padding-bottom: $padding-base-vertical * 8;
}

.pb\+\+\+ {
  padding-bottom: $padding-base-vertical * 16;
}

.pt {
  padding-top: $padding-base-vertical;
}

.pt\+ {
  padding-top: $padding-base-vertical * 4;
}

.pt\+\+ {
  padding-top: $padding-base-vertical * 8;
}

.pt\+\+\+ {
  padding-top: $padding-base-vertical * 16;
}

.ph\+ {
  padding-left: $padding-base-horizontal * 4;
  padding-right: $padding-base-horizontal * 4;
}

.hovering-dots {
  position: relative;
  &::before {
    content: '• • •';
    font-size: $font-size-large;
    position: absolute;
    top: -25px;
  }
}

.img-full {
  width: 100%;
}

@include media-below-xs {
  .border-bottom-xs {
    border-bottom: 1px solid #ececec;
  }

  .text-center-xs {
    text-align: center;
  }

  .hovering-dots--xs-center::before {
    left: 50%;
    transform: translateX(-50%);
  }
}
