@import "variables";

@font-face {
    font-family: 'icomoon';
    src:
        url('#{$icomoon-font-path}/icomoon.ttf?v1ihoo') format('truetype'),
        url('#{$icomoon-font-path}/icomoon.woff?v1ihoo') format('woff'),
        url('#{$icomoon-font-path}/icomoon.svg?v1ihoo#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="icon--"], [class*=" icon--"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon--bussola {
    &:before {
        content: $icon--bussola;
    }
}
.icon--enviar {
    &:before {
        content: $icon--enviar;
    }
}
.icon--youtube {
    &:before {
        content: $icon--youtube;
    }
}
.icon--especificacoes {
    &:before {
        content: $icon--especificacoes;
    }
}
.icon--calendario {
    &:before {
        content: $icon--calendario;
    }
}
.icon--certo {
    &:before {
        content: $icon--certo;
    }
}
.icon--info {
    &:before {
        content: $icon--info;
    }
}
.icon--produtos {
    &:before {
        content: $icon--produtos;
    }
}
.icon--empresa {
    &:before {
        content: $icon--empresa;
    }
}
.icon--estrelas {
    &:before {
        content: $icon--estrelas;
    }
}
.icon--twitter {
    &:before {
        content: $icon--twitter;
    }
}
.icon--google-mais {
    &:before {
        content: $icon--google-mais;
    }
}
.icon--flickr {
    &:before {
        content: $icon--flickr;
    }
}
.icon--vimeo {
    &:before {
        content: $icon--vimeo;
    }
}
.icon--facebook {
    &:before {
        content: $icon--facebook;
    }
}
.icon--download {
    &:before {
        content: $icon--download;
    }
}
.icon--seta-menu {
    &:before {
        content: $icon--seta-menu;
    }
}

