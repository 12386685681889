.row--gutter-none {
  margin-left: 0;
  margin-right: 0;
  > [class*='col-'] {
    padding-left: 0;
    padding-right: 0;
  }
}

.row--double-size {
  left: -50%;
  position: relative;
  width: 200%;
  margin: 0 auto;
}

.row--height-full {
  &, & > [class*='col-'] {
    height: 100%;
  }
}
