.ContentDetails {
}

.ContentDetails__Item {
  @include clearfix;
}

.ContentDetails__Item:nth-child(odd) {
  .ContentDetails__ImgContainer {
    padding-right: 30px;
    margin-right: 30px;
  }
}

.ContentDetails__Item:nth-child(even) {
  .ContentDetails__ImgContainer {
    margin-left: 30px;
    padding-left: 30px;
    text-align: right;
  }
}

.ContentDetails__Item + .ContentDetails__Item {
  border-top: 1px solid #ececec;
  margin-top: $padding-base-vertical * 8;
  padding-top: $padding-base-vertical * 8;
}

.ContentDetails__ImgContainer img {
  max-height: 265px;
  max-width: 100%;
}

.ContentDetails__Specifications {
  dt {
    &::after {
      content: ' › ';
    }
  }
}

.ContentDetails__Description {
  padding: $padding-base-vertical * 8 $padding-base-horizontal;
}

@include media-xs {
  .ContentDetails__Description {
    padding: $padding-base-vertical * 8 $padding-base-horizontal * 3;
    max-width: 480px;
  }
  .ContentDetails__Item:nth-child(odd) .ContentDetails__Description {
    float: right;
  }
}

@include media-md {
  .ContentDetails__Item + .ContentDetails__Item {
    margin-top: $padding-base-vertical * 16;
    padding-top: $padding-base-vertical * 16;
  }

  .ContentDetails__Item:nth-child(odd) {
    .ContentDetails__Description, .ContentDetails__ImgContainer {
      float: left;
    }
  }
  .ContentDetails__Item:nth-child(even) {
    .ContentDetails__Description, .ContentDetails__ImgContainer {
      float: right;
    }
  }

  .ContentDetails__Description {
    padding-top: 0;
    width: 455px;
  }

  .ContentDetails__ImgContainer {
    width: 50%;
    img {
      max-height: none;
      max-width: none;
      width: 100%;
    }
  }
}
