.ContactsMap {
  background-color: $brand-primary;
  position: relative;
  a {
    color: $gray-lighter;
  }
}

.ContactsMap__Map {
  height: 515px;
}

.ContactsMap .container,
.ContactMap__Description {
  height: 100%;
}

#gmap {
  height: 100%;
}


@include media-md {
  .ContactMap__Description {
    background-color: rgba(141, 198, 63, .9);
    z-index: 1;
  }
  .ContactsMap__Map {
    height: auto;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
    top: 0;
  }
}
