.Brochure {
  position: relative;
}

.Brochure__Image {
  display: none;
  position: absolute;
  max-width: 50%;
  max-height: 475px;
  top: 0;
  left: 0;

  @include media-sm {
    display: block;
  }
}

.Brochure__Content {

}
