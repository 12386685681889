.Exportacao {
  background: url(/assets/img/mapa-mobile.png) 30% 0 no-repeat;
  overflow: hidden;
  position: relative;
}

@include media-sm {
  .Exportacao {
    background: none;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: -230px;
      height: 100%;
      width: 1056px;
    }
  }
  html[lang=pt] .Exportacao::before {
    background-image: url(/assets/img/mapa-desktop-pt.png);
  }
  html[lang=en] .Exportacao::before {
    background-image: url(/assets/img/mapa-desktop-en.png);
  }
}

@include media-md {
  .Exportacao::before {
    right: -150px;
  }
}

@include media-lg {
  .Exportacao::before {
    right: 0;
  }
}
