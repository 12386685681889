@mixin media-width-below($max) {
  @media (max-width: $max) {
    @content;
  }
}

@mixin media-width-between($min, $max) {
  @media (min-width: $min) and (max-width: $max) {
    @content;
  }
}

@mixin media-width-above($min) {
  @media (min-width: $min) {
    @content;
  }
}

// XS and above
@mixin media-xs {
  @include media-width-above($screen-xs-min) {
    @content;
  }
}

// SM and above
@mixin media-sm {
  @include media-width-above($screen-sm-min) {
    @content;
  }
}

// MD and above
@mixin media-md {
  @include media-width-above($screen-md-min) {
    @content;
  }
}

// LG and above
@mixin media-lg {
  @include media-width-above($screen-lg-min) {
    @content;
  }
}

// XS and below
@mixin media-below-xs {
  @include media-width-below($screen-xs-max) {
    @content;
  }
}

// SM and below
@mixin media-below-sm {
  @include media-width-below($screen-sm-max) {
    @content;
  }
}

// MD and below
@mixin media-below-md {
  @include media-width-below($screen-md-max) {
    @content;
  }
}
